import { Subscription, timer } from 'rxjs';
import { publishEvent, startListener } from './event-bus.service';
import { EventBus } from '@avaldigitallabs/adl-commons-lib-frontend-event-bus';
import { HttpService } from './http.service';
import { FetchAdapter } from '../adapters/fetch.adapter.service';
import { IResponseHandlerMock } from '../models/responsehandler';
import { URLSMOCK } from '../config/urlmock';
import { environment as env } from '../environments/environment';
import { IConfigEvent, IHearBeat } from '../models/configHearbeat';
export class ListenerEvents {
  private static instance: ListenerEvents;
  private eventBus = EventBus.getInstance();
  private urlMock = URLSMOCK[process.env.environment];
  private subscriptions = new Subscription();
  private environment: string;
  private configHeartBeatEvent: IConfigEvent<{}>;
  private httpService: HttpService;
  counter = 0;
  private constructor() {}
  public static getInstance(): ListenerEvents {
    if (!ListenerEvents.instance) {
      ListenerEvents.instance = new ListenerEvents();
    }
    return ListenerEvents.instance;
  }
  public async main(env: string) {
    this.environment = env;
    this.listenerInfoChannels();
    this.listenerEventMocks();
    await this.executeRequestParallel();
    this.itsAlive();
  }
  private listenerInfoChannels() {
    const channelSubs$ = startListener('basicCustomerInformation').subscribe(
      (data) => {}
    );
    this.subscriptions.add(channelSubs$);
  }
  private listenerEventMocks() {
    if (this.environment !== 'pro') {
      const listenerMocks$ = startListener('TopicMockMicroShell').subscribe(
        (data: any) => {
          if (data) {
            this.getDataMock(data);
          }
        }
      );
      this.subscriptions.add(listenerMocks$);
    }
  }
  async getDataMock(mock: { url: string; topicPublishEvent: string }) {
    try {
      if (mock) {
        this.httpService = HttpService.getInstance(FetchAdapter.getInstance());
        const body = {
          path: mock.url
        };

        const result = await this.httpService.post<
          IResponseHandlerMock,
          { path: string }
        >(this.urlMock, body);
        this.sendEventMockData(result.data, mock.topicPublishEvent);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
    }
  }
  sendEventMockData(data: any, topic: string) {
    publishEvent(topic, data, true);
  }
  public cleanup() {
    this.subscriptions.unsubscribe();
  }

  async executeRequestParallel() {
    try {
      await Promise.all([this.getFlagsMock(), this.getConfigHeartBeat()]);
    } catch (error) {
      console.error('Error al ejecutar las peticiones:', error);
      throw error;
    }
  }

  async getFlagsMock() {
    await this.getDataMock({
      url: 'castlemock/mock/rest/project/X0irk4/application/49tAf0/flagsMockMfes',
      topicPublishEvent: 'flagsMockMfe'
    });
  }

  async getConfigHeartBeat() {
    const url = `${
      env.urlAssets[this.environment]
    }data/config/events/events.json`;
    const key = location.pathname.split('/')[1];
    this.httpService = HttpService.getInstance(FetchAdapter.getInstance());
    const configHeartBeat = await this.httpService.get<IHearBeat<{}>>(url);
    const heartBeat = configHeartBeat[key]?.configHeartBeat;
    if (heartBeat) {
      this.configHeartBeatEvent = heartBeat;
    }
  }
  itsAlive() {
    const $suscriptionTimer = timer(
      0,
      this.configHeartBeatEvent?.timeAlive
    ).subscribe(() => {
      this.sendEventBusHeartBeat();
    });
    this.subscriptions.add($suscriptionTimer);
  }
  sendEventBusHeartBeat() {
    publishEvent(
      this.configHeartBeatEvent?.topicName,
      {
        location: location.pathname
      },
      false
    );
  }
}
